<template>
  <div>
    <v-app-bar
        color="white"
        dense
        light
        flat
    class="nav-bar-page">
      <v-btn
          v-if="showBackButton"
          class="primary--text font-weight-regular pl-0 text-capitalize vardi-button"
          @click="goBack"
          plain>
        <v-icon style="font-size: 1.8em;">mdi-chevron-left</v-icon>
        Atrás
      </v-btn>

      <v-btn
          v-if="showLogoutButton"
          color="white"
          class="primary--text font-weight-regular text-capitalize vardi-button ml-auto px-6"
          @click="dialogConfirm = true"
          >
        Salir
      </v-btn>
    </v-app-bar>

    <v-dialog
        v-model="dialogConfirm"
        max-width="350"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">


        <h2 class="my-4 today-medium justify-center primary--text">¿Quieres salir?</h2>

        <h3 class="my-4 text-justify primary--text today-light line-height-1 px-4">Estas seguro de querer cerrar sesión, haz clic en el botón "continuar"</h3>

        <v-card-actions class="mt-4 mb-2 justify-center">

          <v-btn
              color="white"
              class="mx-2 primary--text font-weight-bold text-capitalize vardi-button ml-auto px-6"
              @click="dialogConfirm = false"
          >
            Cancelar
          </v-btn>

          <v-btn
              color="primary"
              class="mx-2 px-10 text-capitalize today-bold vardi-button"
              @click="logout"
              elevation="0"
          >
            Continuar
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {locals} from "@/helpers/locals";
export default {
  name: 'nav-bar-page',
  data() {
    return {
      noShowLogout: ['/registro-de-documento', '/subir-documento', '/preparate-para-selfie', '/tomar-selfie', '/datos-de-contacto', '/simular-financiacion'],
      noShowBackButton: ['/polizas'],
      showLogoutButton: true,
      showBackButton: true,
      dialogConfirm: false
    }
  },
  created() {
    this.showLogoutButton = !this.noShowLogout.includes(this.$route.path);
    this.showBackButton = !this.noShowBackButton.includes(this.$route.path);

  },
  methods: {
    goBack() {
      let path = this.$route.path;
      if(['/registro-de-documento', '/registro-de-documento'].includes(path)) {
        this.$router.push('/');
      } else {
        this.$router.go(-1);
      }
    },
    logout() {
      locals.clearLocal();
      this.$router.push('/');
    }
  }
}
</script>

<style>
.nav-bar-page > div {
  justify-content: left !important;
}
</style>